import { gql } from '@apollo/client'
import { MEDIA_FIELDS } from './fragments'

const getMediaByScenarioIdQuery = gql`
  ${MEDIA_FIELDS}
  query ($scenarioId: ID) {
    media(scenarioId: $scenarioId) {
      ...MediaFields
      scenario {
        id
        name
      }
      linkedToScenes {
        id
        name
        number
      }
    }
  }
`
const getMediaQuery = gql`
  ${MEDIA_FIELDS}
  query {
    media {
      ...MediaFields
      scenario {
        id
        name
      }
      linkedToScenes {
        id
        name
      }
    }
  }
`
const getMediumQuery = gql`
  ${MEDIA_FIELDS}
  query ($id: ID!) {
    media(id: $id) {
      ...MediaFields
      scenario {
        id
        name
      }
      linkedToScenes {
        id
        name
        number
        scenario {
          id
          name
        }
      }
    }
  }
`
const addMediumMutation = gql`
  ${MEDIA_FIELDS}
  mutation AddMedium($name: String!, $scenarioId: ID, $blobId: String!, $kind: MediumKind!) {
    addMedium(
      name: $name
      kind: $kind
      scenarioId: $scenarioId
      blobId: $blobId
    ) {
      medium {
        ...MediaFields
        linkedToScenes {
          id
          name
          scenario {
            id
            name
          }
        }
        scenario {
          id
          name
        }
      }
    }
  }
`

const updateMediumMutation = gql`
  ${MEDIA_FIELDS}
  mutation updateMedium(
    $id: ID!
    $name: String!
    $blobId: String
    $trimEnabled: Boolean!
    $trimStart: Float
    $trimEnd: Float
  ) {
    updateMedium(
      id: $id
      name: $name
      blobId: $blobId
      trimEnabled: $trimEnabled
      trimStart: $trimStart
      trimEnd: $trimEnd
    ) {
      medium {
        ...MediaFields
        linkedToScenes {
          id
          name
          scenario {
            id
            name
          }
        }
        scenario {
          id
          name
        }
      }
    }
  }
`

const updateMediumTrimMutation = gql`
  ${MEDIA_FIELDS}
  mutation updateMedium(
    $id: ID!
    $name: String!
    $trimEnabled: Boolean!
    $trimStart: Float
    $trimEnd: Float
  ) {
    updateMedium(
      id: $id
      name: $name
      trimEnabled: $trimEnabled
      trimStart: $trimStart
      trimEnd: $trimEnd
    ) {
      medium {
        ...MediaFields
        linkedToScenes {
          id
          name
          scenario {
            id
            name
          }
        }
        scenario {
          id
          name
        }
      }
    }
  }
`

const duplicateMediumMutation = gql`
  mutation ($id: ID!, $scenarioId: ID!) {
    duplicateMedium(id: $id, scenarioId: $scenarioId) {
      originalId
      medium {
        id
        name
      }
    }
  }
`
const deleteMediumMutation = gql`
  mutation ($id: ID!) {
    removeMedium(id: $id) {
      id
    }
  }
`

export {
  getMediaQuery,
  getMediaByScenarioIdQuery,
  getMediumQuery,
  duplicateMediumMutation,
  deleteMediumMutation,
  addMediumMutation,
  updateMediumMutation,
  updateMediumTrimMutation,
}
