import React from 'react'
import { convertMilliSeconds, convertResolution } from '../../../utils/format'
import { FaCut, FaFileVideo } from 'react-icons/fa'
import DataTooltip from '../../UI/DataTooltip'

const FileInformation = ({ medium, showJumpToTimes = true }) => {
  if (!medium) return null

  const jumpToTimeHandler = (timeInSeconds) => {
    const el = document.querySelector(`#video-${medium.id}`)
    if (el) {
      el.currentTime = timeInSeconds
      el.pause()
    }
  }

  const getMediaInfo = () => {
    const data = [
      { name: 'File name', item: medium.name },
      {
        name: 'Type',
        item: <span>{medium.stereoscopic ? 'Stereo 360°' : 'Mono'} video</span>,
      },
      ...(['FLAT_VIDEO', 'FLAT_IMAGE'].includes(medium.kind)
        ? []
        : [{ name: 'Length', item: convertMilliSeconds(medium.duration) }]),
      ...(medium.kind === 'VIDEO_360'
        ? [
            {
              name: 'Framerate',
              item: `${medium.fps} FPS`,
            },
            {
              name: 'Resolution',
              item: convertResolution(medium.width),
            },
          ]
        : []),
    ]

    return data.map((i, index) => (
      <li
        key={index}
        className={index < data.length - 1 ? 'mb-1' : ''}
        style={{ listStyle: 'none' }}>
        <span>{i.name}</span>
        <span className="float-right">{i.item}</span>
      </li>
    ))
  }

  const getTrimInfo = () => {
    if (!medium.trimEnabled) return null

    const renderTimeStart = () =>
      convertMilliSeconds(medium.trimStart * 1000 || 0)

    const renderTimeEnd = () =>
      convertMilliSeconds(medium.trimEnd * 1000 || medium.duration)

    const timeClassNames = `${showJumpToTimes ? 'text-underline' : ''} text-stable-dark float-right`

    return (
      <div
        className="border-light-top pt-1-5 pb-1-5 pl-2 pr-2 flex-container align-middle align-justify text-normal"
        data-testid="trimmed">
        <div className="flex-container align-middle">
          <span className="o-label o-label--stable text-stable-dark mr-1">
            <FaCut />
          </span>
          Video is trimmed
        </div>
        <div className="flex-container">
          {showJumpToTimes ? (
            <DataTooltip position="left" title="Jump to">
              <a
                data-testid="trimmed-link--start"
                className={`${timeClassNames} cursor-pointer`}
                onClick={() => jumpToTimeHandler(medium.trimStart)}>
                {renderTimeStart()}
              </a>
            </DataTooltip>
          ) : (
            <span className={timeClassNames}>{renderTimeStart()}</span>
          )}
          <span>&nbsp; - &nbsp;</span>
          {showJumpToTimes ? (
            <DataTooltip position="left" title="Jump to">
              <a
                data-testid="trimmed-link--end"
                className={timeClassNames}
                onClick={() => jumpToTimeHandler(medium.trimEnd)}>
                {renderTimeEnd()}
              </a>
            </DataTooltip>
          ) : (
            <span className={timeClassNames}>{renderTimeEnd()}</span>
          )}
        </div>
      </div>
    )
  }

  return (
    <div id="media-information" className="border-light border-radius mt-2">
      <h3 className="p-2 border-light-bottom text-normal text-bold flex-container align-middle mb-0">
        <FaFileVideo className="mr-1" />
        File information
      </h3>
      <div
        className="list text-normal pt-1-5 pb-1-5 pr-2 pl-2 mb-0"
        data-testid="fileinfopane-rows">
        {getMediaInfo()}
      </div>
      {getTrimInfo()}
    </div>
  )
}

export default FileInformation
