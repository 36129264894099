import React, { useCallback, useContext } from 'react'
import { FaVideo } from 'react-icons/fa'
import { Handle, Position, useReactFlow } from 'reactflow'
import {
  getDisplayedNodeElements,
  getPointClass,
} from '../../../helpers/elementHelper'
import {
  isMultiSelection,
  useSortedNodeHandles,
} from '../../../helpers/nodeHelper'
import { ScenarioEditorContext } from '../../../context/ScenarioEditorProvider'
import { unSelectHoverEdges } from '../../../helpers/edgeHelper'
import NodeElementCard from '../elements/NodeElementCard'
import HomeLabel from '../elements/HomeLabel'
import SceneNumberLabel from '../elements/SceneNumberLabel'

const SceneNode = (node) => {
  const { scenario } = useContext(ScenarioEditorContext)
  const { data } = node
  const { target: targetHandles, source: allSourceHandles } =
    useSortedNodeHandles(data)
  const reactFlow = useReactFlow()
  const edges = reactFlow.getEdges()

  const renderElements = useCallback(() => {
    if (!data.elements?.length) return <></>

    const elements = getDisplayedNodeElements(data, true)

    return (
      <div className="o-scene__elements">
        {elements.map((el) => (
          <NodeElementCard
            key={el.id ?? el.groupUuid}
            element={el}
            nodeData={data}
            allSourceHandles={allSourceHandles}
            answerElements={el.answerElements}
            index={el.index}
          />
        ))}
      </div>
    )
  }, [node, allSourceHandles])

  // The mouseout event is in ScenarioEditor component
  const handleTargetMouseEnter = useCallback(
    (e) => {
      const { handleid } = e.currentTarget.dataset
      const edge = edges.find((e) => e.targetHandle === handleid)
      if (edge)
        reactFlow.setEdges(
          edges.map((e) =>
            e.id === edge.id
              ? {
                  ...e,
                  selected: true,
                  data: {
                    ...e.data,
                    selectType: 'hover',
                  },
                }
              : e
          )
        )
    },
    [edges]
  )

  const handleMouseEnter = useCallback(() => {
    unSelectHoverEdges(reactFlow)
  }, [])

  return (
    <>
      {!!targetHandles.length && (
        <div
          onMouseEnter={handleMouseEnter}
          className="o-scene__target-handle-container">
          {targetHandles.map((h) => {
            const edge = edges.find((e) => e.targetHandle === h.id)
            return (
              <Handle
                key={h.id}
                type="target"
                position={Position.Left}
                id={h.id}
                className={`o-scene__handle o-scene__handle--target
                o-scene__handle--${getPointClass(h.points, scenario.scoringSystem.kind)}
                ${!isMultiSelection() && h.selected && edge.data.selectType !== 'hover' ? 'o-scene__handle--selected' : ''}
              `}
                isConnectableEnd={true}
                isConnectableStart={false}
                onMouseEnter={handleTargetMouseEnter}
              />
            )
          })}
        </div>
      )}
      <Handle
        className="o-scene__handle--main"
        position={Position.Left}
        type="target"
        isConnectable={true}
        id={`e${data.id}`}
      />
      <div
        id={`o-scene-${data.id}`}
        className="o-scene"
        style={{
          minHeight: `${20 + (targetHandles?.length > 1 ? targetHandles?.length : 1) * (28 + 10) - 10}px`,
        }}
        onMouseEnter={handleMouseEnter}>
        <h2
          className={`o-scene__title ${node.data.elements?.length ? 'border-light-bottom' : ''}`}>
          <span className="text-left">
            {data.name}
            {process.env.NODE_ENV === 'development' && ` => ${node.id}`}
          </span>
          <span className="flex-container">
            {data.hasVideo && (
              <span className="o-label o-label--royal text-white text-small mr-0-5">
                <FaVideo />
              </span>
            )}
            {data.start && <HomeLabel className="mr-0-5 text-small" />}
            <SceneNumberLabel number={data.number} className="text-small" />
          </span>
        </h2>

        {renderElements()}
      </div>
    </>
  )
}

export default SceneNode
