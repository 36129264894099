import React from 'react'
import PropTypes from 'prop-types'
import { FaMinus, FaPlus } from 'react-icons/fa'

const NumberInput = ({ value = 0, changeHandler, step = 1, min, max }) => {
  const validate = (newValue, key = 'min') => {
    if (key === 'min' && min !== undefined)
      return newValue >= 0 && newValue + step >= min
    if (key === 'max' && max !== undefined) return newValue - step < max
    return true
  }
  const onChange = (newValue, key) =>
    validate(newValue, key) && changeHandler(newValue)

  return (
    <div data-testid="wrapper" className="o-number-input">
      <button
        type="button"
        onClick={() => onChange(value - step, 'min')}
        disabled={!validate(value - step, 'min')}>
        <FaMinus />
      </button>
      <span>{value}</span>
      <button
        type="button"
        onClick={() => onChange(value + step, 'max')}
        disabled={!validate(value + step, 'max')}>
        <FaPlus />
      </button>
    </div>
  )
}

export default NumberInput

NumberInput.propTypes = {
  changeHandler: PropTypes.func,
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
}
