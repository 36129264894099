import React, { useRef } from 'react'
import { useReactFlow } from 'reactflow'
import TransparentInput from './TransparentInput'
import { setNode } from '../../helpers/nodeHelper'
import useAddUpdateScene from '../../hooks/useAddUpdateScene'

const MAX_HEIGHT = 135
const MIN_HEIGHT = 58

const DescriptionField = ({ scene, selectedNode }) => {
  const reactFlow = useReactFlow()
  const inputRef = useRef()
  const { updateSceneDetails } = useAddUpdateScene()

  if (!selectedNode) return null

  const handleChangeDescription = ({ target: { value } }) => {
    const { id, description } = selectedNode.data
    if (value !== description) {
      updateNodeDescription(value)
      updateSceneDetails({
        id,
        description: value,
      })
    }
  }

  const updateNodeDescription = (value) => {
    setNode(reactFlow, {
      ...selectedNode,
      data: { ...selectedNode.data, description: value },
    })
  }

  return (
    <TransparentInput
      id={`description-${selectedNode.id}`}
      name="label"
      type="htmlEditor"
      placeholder="Add actors, filming locations or anything else that will describe this scene. This information is never shown to trainees."
      onConfirm={handleChangeDescription}
      value={selectedNode.data.description}
      maxLength="500"
      ref={inputRef}
      multiline
      maxHeight={MAX_HEIGHT}
      minHeight={MIN_HEIGHT}
      focusedMinHeight={102}
    />
  )
}

export default DescriptionField
