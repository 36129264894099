import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react'
import { FaCheckCircle, FaTimes } from 'react-icons/fa'

import HtmlEditor from '../../../UI/HtmlEditor/HtmlEditor'

const STORAGE_KEY = 'inputDrafts'
const MAX_MORE_HEIGHT = 2000

const TransparentInput = forwardRef(
  (
    {
      id = '',
      onConfirm = () => {},
      onCancel = () => {},
      placeholder = 'Add a label...',
      className = '',
      minHeight = 37,
      focusedMinHeight = 37,
      maxHeight = 75,
      value: defaultValue = '',
      multiline = false,
      style = {},
      useDraft = true,
      onTriggerFocus = () => {},
      type,
      ...props
    },
    outerRef
  ) => {
    const [hovered, setHovered] = useState(false)
    const [focused, setFocused] = useState(false)
    const [value, _setValue] = useState(defaultValue)
    const innerRef = useRef(null)
    const isMouseDownInside = useRef(null)
    const draftRef = useRef(null)
    useImperativeHandle(outerRef, () => innerRef.current, [])

    const [showMoreDesc, setShowMoreDesc] = useState()
    const [heightOver, setHeightOver] = useState()

    const setValue = (value) => {
      _setValue(value)
      if (type === 'htmlEditor' || !innerRef.current) return
      if (type === 'contentEditable') {
        innerRef.current.innerHTML = value ?? ''
      } else {
        innerRef.current.value = value
      }
    }

    const handleCancel = () => {
      setValue(defaultValue)
      clearDraft()
      onCancel()
    }

    const handleConfirm = (e) => {
      e.stopPropagation()
      const value = ['contentEditable', 'htmlEditor'].includes(type)
        ? innerRef.current.innerHTML
        : innerRef.current.value
      clearDraft()
      onConfirm({
        target: { name: props.name, value },
      })
      setHovered(false)
      setFocused(false)
      setValue(value)
      innerRef.current.blur()
    }

    const loadDraft = () => {
      const draftValue = localStorage.getItem(STORAGE_KEY)
      return draftValue ? JSON.parse(draftValue) : {}
    }

    const saveDraft = () => {
      const draftValue = draftRef.current
      const draft = loadDraft()
      if (draft[id] === draftValue) return
      localStorage.setItem(
        STORAGE_KEY,
        JSON.stringify({ ...draft, [id]: draftValue })
      )
    }

    const restoreDraft = () => {
      const draft = loadDraft()[id]
      draftRef.current = draft
      if (draft) {
        if (type === 'htmlEditor') return setValue(draft)
        if (type === 'contentEditable') innerRef.current.innerHTML = draft
        else innerRef.current.value = draft
      }
    }

    const clearDraft = () => {
      const { ...draft } = loadDraft()
      draftRef.current = ''
      delete draft[id]
      localStorage.setItem(STORAGE_KEY, JSON.stringify({ ...draft }))
    }

    const handleClickOutside = () => {
      // return when user is selecting a text
      if (isMouseDownInside.current) return

      setFocused(false)
      if (innerRef.current) innerRef.current.blur()

      // double setting value to trigger re-render
      setValue('')
      setTimeout(() => {
        setValue(defaultValue)
      }, 0)
    }

    const renderInput = () => {
      const genericProps = {
        className: `border-0 border-radius mb-0 text-normal text-dark pl-1 tracking-tighter 
          ${hovered ? 'bg-stable-lightest' : ''} 
          ${focused ? 'bg-stable-lighter' : ''} 
          ${multiline ? 'pb-3' : ''}
          ${!hovered && !focused ? 'bg-transparent' : ''} 
          ${className}
        `,
        style: {
          ...(!multiline ? { paddingRight: '60px' } : {}),
          ...style,
        },
        ...props,
        ref: innerRef,
        onClick: (e) => {
          e.stopPropagation()
          setFocused(true)
        },
        onMouseDown: (e) => {
          isMouseDownInside.current = true
        },
        onMouseUp: (e) => {
          isMouseDownInside.current = false
        },
        onKeyPress: (e) => {
          if (e.key === 'Enter' && !multiline) {
            e.preventDefault()
            handleConfirm(e)
            return false
          }
        },
        onFocus: (e) => {
          setFocused(true)
          restoreDraft()
          const currentValue = ['contentEditable', 'htmlEditor'].includes(type)
            ? e.target.innerHTML
            : e.target.value
          props?.onFocus?.(currentValue)
        },
        onBlur: (e) => {
          const currentValue = ['contentEditable', 'htmlEditor'].includes(type)
            ? e.target.innerHTML
            : e.target.value
          props?.onBlur?.(currentValue)
        },
        onKeyUp: (e) => {
          e.stopPropagation()
        },
        onKeyDown: (e) => {
          e.stopPropagation()
        },
      }

      if (type === 'htmlEditor') {
        const calculateMaxHeight = () => {
          if (showMoreDesc) return `${MAX_MORE_HEIGHT}px`
          if (focused) return 'none'
          return `${value ? maxHeight : minHeight}px`
        }

        return (
          <>
            <div
              style={{
                maxHeight: calculateMaxHeight(),
                overflow: 'hidden',
                transition: 'all .2s',
                height: 'auto',
              }}>
              <HtmlEditor
                containerProps={{
                  ...genericProps,
                  style: {
                    minHeight: `${focused ? focusedMinHeight : minHeight}px`,
                    overflow: 'hidden',
                    outline: 'none',
                    padding: '0.5rem',
                    transition: 'all 0.2s',
                    wordBreak: 'break-word',
                    cursor: 'text',
                    ...genericProps.style,
                  },
                }}
                onUpdate={({ editor }) => {
                  const content = editor.getHTML()
                  draftRef.current = content
                  if (useDraft) saveDraft()
                }}
                value={value}
                focused={focused}
                id={id}
                placeholder={placeholder}
              />
            </div>
            {heightOver && !focused && (
              <button
                className="cursor-pointer text-underline text-stable-dark text-small mt-1 pl-1 bg-transparent tracking-tighter"
                onClick={() => setShowMoreDesc(!showMoreDesc)}>
                {showMoreDesc ? 'Show less' : 'Show more'}
              </button>
            )}
          </>
        )
      }

      if (type === 'contentEditable')
        return (
          <div
            {...genericProps}
            contentEditable="plaintext-only"
            suppressContentEditableWarning={true}
            data-placeholder={placeholder}
            tabIndex={100}
            style={{
              minHeight: `${focused ? focusedMinHeight : minHeight}px`,
              overflow: 'hidden',
              outline: 'none',
              padding: '0.5rem',
              transition: 'all 0.2s',
              wordBreak: 'break-word',
              ...genericProps.style,
            }}>
            {value}
          </div>
        )

      return (
        <input
          {...genericProps}
          type="text"
          onKeyUp={(e) => {
            if (e.key === 'Enter') handleConfirm(e)
          }}
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            draftRef.current = e.target.value
            if (useDraft) saveDraft()
          }}
        />
      )
    }

    const getScrollHeight = () => innerRef.current?.scrollHeight

    useEffect(() => {
      setTimeout(() => {
        setValue(defaultValue)
      }, 200)
    }, [defaultValue])

    useEffect(() => {
      onTriggerFocus(focused)
      if (focused) document.body.addEventListener('click', handleClickOutside)
      else
        setTimeout(() => {
          document.body.removeEventListener('click', handleClickOutside)
        }, 500)

      return () => {
        document.body.removeEventListener('click', handleClickOutside)
      }
    }, [focused])

    useEffect(() => {
      if (!focused && type === 'htmlEditor') {
        setTimeout(() => {
          // calculate height after ending of transition
          setHeightOver(getScrollHeight() > maxHeight + 10)
        }, 300)
      }
    }, [focused, id])

    useEffect(() => {
      setFocused(false)
    }, [id])

    return (
      <div
        className="w-100 h-100"
        style={{ position: 'relative', maxHeight: '100%' }}
        hidden={props.hidden}
        onMouseEnter={setHovered}
        onMouseLeave={() => {
          setHovered(false)
        }}>
        <div
          style={{
            position: 'absolute',
            right: '10px',
            bottom: `${['contentEditable', 'htmlEditor'].includes(type) === 'contentEditable' ? 1 : 0}px`,
          }}
          className="bg-stable-lighter pl-0-5 text-xxlarge"
          hidden={!focused}>
          <button
            onClick={handleCancel}
            className="text-stable-dark cursor-pointer">
            <FaTimes />
          </button>
          <button
            onClick={handleConfirm}
            className="text-assertive ml-1 cursor-pointer">
            <FaCheckCircle />
          </button>
        </div>
        {renderInput()}
      </div>
    )
  }
)

export default TransparentInput
