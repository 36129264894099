import React, { useState, useEffect, useContext } from 'react'
import { FaPlus, FaEllipsisH, FaLink } from 'react-icons/fa'
import { useQuery, useMutation } from '@apollo/client'
import {
  getScenesQuery,
  linkSceneMutation,
  unlinkMediumToSceneMutation,
} from '../../../apollo/query/scenes'
import SearchableDropdown from '../../UI/Menu/SearchableDropdown'
import { handleApolloError } from '../../../utils/errors'
import Dropdown from '../../UI/Menu/Dropdown'
import { MediumContext } from './MediumContext'
import { FaLinkSlash } from 'react-icons/fa6'

const SceneInformation = ({
  refetch: refetchMedium = () => {},
  updateVideoCallback,
  openSceneCallback,
}) => {
  const {
    medium: [medium],
  } = useContext(MediumContext)
  const [showSceneSelector, setShowSceneSelector] = useState(false)
  const [baseOptions, setBaseOptions] = useState(null)
  const [scenes, setScenes] = useState([])

  const formatScenes = (sceneOptions) => {
    return sceneOptions.map((s) => ({
      ...s,
      value: `${s.number} ${s.name}`,
      hasVideo: s.hasVideo,
      render: (
        <div key={s.id} data-testid={`scene-${s.id}`} id={`scene-${s.id}`}>
          <span className="scene-icon">
            <span>{s.number}</span>
          </span>
          <span>{s.name}</span>
          {s.hasVideo && (
            <span
              data-testid={`scene-${s.id}-video-icon`}
              className="scene-video-icon"
              title="scene is linked to a video"
            />
          )}
        </div>
      ),
    }))
  }

  const filterLinkedScenes = () => {
    // filter out currently linked scenarios:
    const linkedSceneIds = medium.linkedToScenes.map((s) => s.id)
    const sceneOptions = [
      ...scenes.filter((scene) => !linkedSceneIds.includes(scene.id)),
    ].sort((a, b) => a.number - b.number)
    setBaseOptions(formatScenes(sceneOptions))
  }
  const { loading, refetch } = useQuery(getScenesQuery, {
    variables: {
      id: medium.scenario.id,
    },
    onCompleted: ({ scenarios }) => {
      setScenes(scenarios[0].scenes)
    },
    fetchPolicy: 'no-cache',
  })

  const [linkScene] = useMutation(linkSceneMutation, {
    onError: handleApolloError,
    onCompleted: filterLinkedScenes,
  })

  const [unlinkScene] = useMutation(unlinkMediumToSceneMutation, {
    onError: handleApolloError,
    onCompleted: () => {
      refetchMedium()
      filterLinkedScenes()
    },
  })
  useEffect(() => {
    if (scenes) {
      filterLinkedScenes()
    }
  }, [scenes])

  if (loading) return <div></div>
  if (!medium)
    throw new Error(
      'No media or scenario found. This should not happen, please refresh the page and try again'
    )

  const addSceneHandler = (scene) => {
    linkScene({
      variables: {
        mediumId: medium.id,
        sceneId: scene.id,
      },
      onCompleted: () => {
        refetch()
        refetchMedium().then(({ data: { media } }) => {
          updateVideoCallback(scene.id, media[0])
        })
      },
    })
  }

  const getCount = () => {
    const { length } = medium.linkedToScenes.filter(
      (s) => s.scenario.id === medium.scenario.id
    )
    if (!length) return 'no scenes'
    if (length === 1) return `${length} scene`
    return `${length} scenes`
  }

  const createScenes = () => {
    const renderScenes = [...scenes]
      .sort((a, b) => a.number - b.number)
      .reduce((a, scene, index) => {
        const linkedScenes = medium.linkedToScenes.filter(
          (s) => s.scenario.id === medium.scenario.id
        )
        if (!linkedScenes || !linkedScenes.find(({ id }) => id === scene.id))
          return a

        const isV2 = !!window.location.hash
        const sceneLink = isV2
          ? `#/scenes/${scene.id}`
          : `/scenarios/${medium.scenario.id}/cards/${scene.id}`
        const videoLink = isV2
          ? `${sceneLink}/video`
          : `/scenarios/${medium.scenario.id}/cards/${scene.id}/editor`
        a.push(
          <li key={index}>
            <span className="scene-info">
              <span className="scene-icon">
                <span>{scene.number}</span>
              </span>
              <a
                href={sceneLink}
                target="_tab"
                className="text-dark scene-link">
                {scene.name}
              </a>
            </span>
            <div className="delete-icon">
              <Dropdown
                id={`scene-options-${scene.id}`}
                alignment="right"
                hover={true}
                offset={{ left: '20px', top: '0px' }}
                button={
                  <button
                    data-open={`scene-options-${scene.id}`}
                    className="cursor-pointer button secondary hollow button-alt mb-0">
                    <FaEllipsisH />
                  </button>
                }>
                <li
                  className="o-dropdown__list-item text-dark"
                  onClick={() => {
                    if (openSceneCallback)
                      return openSceneCallback(scene.id, sceneLink)
                    window.location.href = sceneLink
                  }}>
                  Open scene in flow editor
                </li>
                <li
                  className="o-dropdown__list-item text-dark"
                  onClick={() => {
                    if (openSceneCallback)
                      return openSceneCallback(scene.id, videoLink)
                    window.location.href = videoLink
                  }}>
                  Open scene in video editor
                </li>
                <hr className="mb-1 mt-1" />
                <li
                  className="o-dropdown__list-item text-dark flex-container align-middle"
                  onClick={() => {
                    unlinkScene({
                      variables: {
                        mediumId: medium.id,
                        sceneId: scene.id,
                      },
                      onCompleted: () => {
                        refetchMedium()
                        refetch()
                        updateVideoCallback(scene.id, null)
                      },
                    })
                  }}>
                  <FaLinkSlash className="mr-1" />
                  Unlink scene
                </li>
              </Dropdown>
            </div>
          </li>
        )
        return a
      }, [])
    if (!scenes.length || !renderScenes.length) {
      return (
        <li className="text-dark scene-link text-no-decoration">
          <span
            className="text-stable-dark scene-info cursor-pointer"
            onClick={() => setShowSceneSelector(true)}>
            No scenes linked yet...
          </span>
        </li>
      )
    }
    return renderScenes
  }

  const renderScenes = () => (
    <SearchableDropdown
      placeholder="Search by scene number or title..."
      loading={loading}
      show={showSceneSelector}
      hideHandler={() => setShowSceneSelector(false)}
      clickHandler={(item) => {
        if (item.hasVideo) {
          // An item is already linked, show confirm state
          const baseOptionItem = baseOptions.find((bO) => bO.id === item.id)
          const index = baseOptions.indexOf(item)
          if (!baseOptionItem.isSelected) {
            baseOptionItem.isSelected = true
            baseOptionItem.render = (
              <div key={item.id} id={`scene-${item.id}`}>
                <span
                  data-testid={`scene-${item.id}-hasvideo`}
                  className="scene-confirm"
                  onClick={() => {
                    addSceneHandler(item)
                  }}>
                  <span className="text-assertive">
                    Scene {item.number} contains a video. Click again to
                    replace.
                  </span>
                </span>
              </div>
            )
            const newBO = [...baseOptions]
            newBO[index] = baseOptionItem
            setBaseOptions(newBO)
          } else {
            // An item in confirm state is clicked, show default state
            const reset = formatScenes([baseOptionItem])[0]
            reset.isSelected = false
            const newBO = [...baseOptions]
            newBO[index] = reset
            setBaseOptions(newBO)
          }
        } else {
          addSceneHandler(item)
        }
      }}
      data={baseOptions}
      position='right'
    />
  )

  return (
    <div className="o-modal__column--scenes">
      <div className="border-light border-radius">
        <div
          data-testid="header"
          className="o-modal--header-h3 flex-container align-middle align-justify p-1 pl-2 pr-1 border-light-bottom">
          <h3 className="text-bold flex-container align-middle">
            <FaLink className="mr-1" />
            Linked to {getCount()}
          </h3>
          <div>
            <button
              id="add-scene"
              data-testid="add-scene-button"
              className="button mb-0 hollow secondary text-bold scene-info pb-0-5 pt-0-5 pr-1 pl-1"
              onClick={() => setShowSceneSelector(!showSceneSelector)}>
              <FaPlus className="text-normal" style={{ marginRight: '4px' }} />
              Add scene
            </button>
            {renderScenes()}
          </div>
        </div>
        <div>
          <ul className="o-modal__video--list">{createScenes()}</ul>
        </div>
      </div>
    </div>
  )
}

export default SceneInformation
