import { useEffect, useState } from 'react'
import { DirectUpload } from '@rails/activestorage'
import { useMutation } from '@apollo/client'
import { v4 as uuidv4 } from 'uuid'
import {
  getMediaQuery,
  addMediumMutation,
  updateMediumMutation,
} from '../apollo/query/media'
import cache from '../apollo/cache'
import { removeAlert, addSuccessAlert } from '../utils/helpers/alerts'
import { default as S } from '../utils/lang/en.json'

const DIRECT_UPLOAD_URL = '/rails/active_storage/direct_uploads'
const MAXIMUM_FILE_SIZE = 5368709120 // 5 Gigabyte upload limit

const useMediaUploader = () => {
  const [addMedium] = useMutation(addMediumMutation, {
    onCompleted: ({ addMedium: aM }) => {
      const existingFiles = cache.readQuery({ query: getMediaQuery })
      const media = existingFiles?.media || []
      const newCache = [...media, aM.medium]
      cache.writeQuery({
        query: getMediaQuery,
        data: { media: [...newCache] },
      })
    },
  })
  const [updateMedium] = useMutation(updateMediumMutation)
  const [files, setFiles] = useState([])
  const [notificationId, setNotificationId] = useState(null)
  const [isUploading, setIsUploading] = useState(false)

  const setNotification = (done) => {
    if (done) {
      addSuccessAlert({
        title: S.notifications.upload.success.title,
        subtitle: S.notifications.upload.success.subtitle,
      })
    } else {
      const alertId = addSuccessAlert({
        title: S.notifications.upload.inProgress.title,
        subtitle: S.notifications.upload.inProgress.subtitle,
        icon: 'upload',
        timeout: false,
        dismissable: false,
      })

      setNotificationId(alertId)
    }
  }

  useEffect(() => {
    if (files.length && !notificationId) {
      setNotification(false)
    }
    if (!files.length && notificationId) {
      // done uploading
      removeAlert(notificationId)
      setNotificationId(null)
      setNotification(true)
    }
  }, [files])

  useEffect(() => {
    return () => {
      removeAlert(notificationId)
      setFiles([])
    }
  }, [])

  const updateFile = (file) => {
    setFiles((state) => state.map((f) => (f.id === file.id ? file : f)))
  }

  const deleteFile = (file) => {
    setFiles((state) => state.filter((f) => f.id !== file.id))
  }

  const uploadDelegate = (file) => {
    const updateProgress = (f, e) => {
      const progress = Math.round((100 * e.loaded) / e.total)
      f.progress = progress
      updateFile(f)
    }

    const onStartUpload = (xhr) => {
      xhr.upload.addEventListener('progress', (event) =>
        updateProgress(file, event)
      )
      xhr.upload.addEventListener('load', () => {
        file.state = 'COMPLETED'
        updateFile(file)
      })
    }

    return { directUploadWillStoreFileWithXHR: onStartUpload }
  }

  const uploadFile = (file) => {
    if (file.size >= MAXIMUM_FILE_SIZE) {
      file.error = 'File exceeds maximum size of 5GB'
      file.state = 'UPLOADING_FAILED'
      updateFile(file)

      setIsUploading((state) => !state)
      return true
    }

    file.state = 'UPLOADING'
    updateFile(file)

    const upload = new DirectUpload(
      file,
      DIRECT_UPLOAD_URL,
      uploadDelegate(file)
    )
    upload.create((error, blob) => {
      if (error) {
        file.error = error
        updateFile(file)
      } else {
        if (file.mediumId)
          updateMedium({
            variables: {
              id: file.mediumId,
              name: file.name,
              trimEnabled: file.trimEnabled,
              trimStart: file.trimStart,
              trimEnd: file.trimEnd,
              blobId: blob.signed_id,
            },
          })
        else
          addMedium({
            variables: {
              scenarioId: file.sid,
              kind: file.kind,
              name: file.name,
              blobId: blob.signed_id,
            },
          })
        deleteFile(file)
      }
      setIsUploading((state) => !state)
    })
  }

  const startNextUpload = () => {
    const pendingUploads = files.filter((f) => f.error === null)

    if (pendingUploads.length > 0) {
      uploadFile(pendingUploads[0])
    }
  }

  useEffect(() => {
    startNextUpload()
  }, [isUploading])

  const handleUploadFiles = (f, userCancel) => {
    if (userCancel) {
      return setFiles((state) => [
        ...state.filter((file) => file.state === 'UPLOADING'),
      ])
    }
    const filesToUpload = f.map((file) => {
      file.id = uuidv4()
      file.error = null
      file.state = 'QUEUED_FOR_UPLOADING'
      file.progress = 0
      return file
    })
    setFiles((state) => [...state, ...filesToUpload])
    if (!isUploading) {
      return setIsUploading((state) => !state)
    }
    return null
  }

  return [files, handleUploadFiles]
}

export default useMediaUploader
