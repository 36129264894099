import React, { useEffect, useState } from 'react'
import { FaChevronDown, FaPlus } from 'react-icons/fa'
import { useReactFlow } from 'reactflow'
import { v4 as uuidV4 } from 'uuid'
import { setNode } from '../../helpers/nodeHelper'
import emptySceneElementsArrow from '../../../../../../images/empty-scene-elements-arrow.svg'
import ElementCard from './ElementCard'
import {
  AUTO_ADD_CHILD_ELEMENT_TYPES,
  ELEMENT_TYPES,
  GROUP_ELEMENT_TYPES,
  getDisplayedNodeElements,
  unSelectSidebarElements,
} from '../../helpers/elementHelper'
import useAddUpdateElement from '../../hooks/useAddUpdateElement'
import useSelectedNode from '../../hooks/useSelectedNode'
import HotspotSettings from '../../../EditorV2/Video/Tabs/Partials/HotspotSettings'
import useShowVideoEditor from '../../hooks/useShowVideoEditor'
import useHasHotspot from '../../hooks/useHasHotspot'
import { unSelectCollistionBox } from '../../../Editor/helpers/controls'
import useCurrentUser from '../../../../hooks/useCurrentUser'
import { MediumProvider } from '../../../Media/Modal/MediumContext'
import Modal from '../../../UI/Modal'
import MediumModal from '../../../Media/Modal/MediumModal'

const ElementButton = ({ type, disabled, loading, setLoading }) => {
  const buttonRow = ELEMENT_TYPES.find((button) => button.type === type)
  const selectedNode = useSelectedNode()
  const reactFlow = useReactFlow()
  const groupUuid = uuidV4()

  if (!selectedNode) return <></>

  const { addElement } = useAddUpdateElement()

  const handleAddElement = () => {
    let variables = {
      sceneId: selectedNode.id,
      kind: type,
    }

    if (type === 'TIMER')
      variables = { ...variables, timerMinutes: 0, timerSeconds: 10 }

    let promises = []
    const groupElRow = GROUP_ELEMENT_TYPES.find((g) => g.type === type)
    if (groupElRow) {
      variables = {
        ...variables,
        groupKind: type,
        groupUuid,
      }
      promises = groupElRow.subEls
        .filter((s) => AUTO_ADD_CHILD_ELEMENT_TYPES.includes(s))
        .map((s) =>
          addElement(
            {
              variables: { ...variables, kind: s },
            },
            selectedNode
          )
        )
    } else {
      promises = [
        addElement(
          {
            variables,
          },
          selectedNode
        ),
      ]
    }

    setLoading(true)
    Promise.all(promises).then((responses) => {
      const newNode = {
        ...selectedNode,
        selected: true,
        data: {
          ...selectedNode.data,
          elements: [
            ...(selectedNode.data?.elements ?? []),
            ...responses.map((r) => r.data.addElement.element),
          ],
        },
      }
      setNode(reactFlow, newNode)
      setLoading(false)
    })
  }

  return (
    <button
      className="pt-1 pb-1 pl-1-5 pr-1-5 flex-container align-middle text-normal text-bold mr-1 mb-1 o-button o-button--light min-w-0"
      onClick={handleAddElement}
      disabled={disabled || loading}>
      {buttonRow.icon}
      <span className="ml-0-5">{buttonRow.title}</span>
    </button>
  )
}

const DEFAULT_MAX_HEIGHT = '300px'

const ElementsTab = ({ selectedNode }) => {
  const [, currentClient] = useCurrentUser()
  const [accOpen, setAccOpen] = useState(false)
  const handleAccordion = () => setAccOpen(!accOpen)
  const showVideoEditor = useShowVideoEditor()
  const hasHotspot = useHasHotspot()
  const [loading, setLoading] = useState(false)
  const [selectedMediumId, setSelectedMediumId] = useState(null)

  useEffect(() => {
    if (selectedNode) setAccOpen(!selectedNode.data.elements?.length)
  }, [selectedNode?.id])

  if (!currentClient) return null

  const renderQuestions = () => {
    if (!selectedNode.data.elements?.length)
      return (
        <div className="text-center pt-5 mt-4" style={{ position: 'relative' }}>
          <img
            src={emptySceneElementsArrow}
            alt="Empty sidebar"
            width="40px"
            style={{
              position: 'absolute',
              left: 'calc(50% + 95px)',
              top: '-10px',
            }}
          />
          <h3 className="text-large text-bold">Add your first element</h3>
          <p>
            <a
              href="https://help.warpvr.com/scenes/jjFsB7uc6Zz1fHqDWfkVMs/scene-types/vF5kusUUzmfTN1TFe6CejE"
              target="_blank"
              className="text-underline text-stable-dark">
              What is an element?
            </a>
          </p>
        </div>
      )

    const handleClick = (e) => {
      if (e.target.id === 'element-container') {
        unSelectSidebarElements()
        unSelectCollistionBox()
      }
    }

    const elements = getDisplayedNodeElements(selectedNode.data)
    return (
      <div
        id="element-container"
        onClick={handleClick}
        style={{ paddingBottom: elements.length ? '180px' : '' }}>
        {showVideoEditor && hasHotspot && <HotspotSettings />}
        <div
          className={`pr-3 pl-3 pb-5 ${showVideoEditor && hasHotspot ? '' : 'pt-3'}`}>
          {elements.map((el, index) => (
            <ElementCard
              key={el.id}
              element={el}
              answerElements={el.answerElements}
              index={el.index}
              loading={loading}
              setLoading={setLoading}
              setSelectedMediumId={setSelectedMediumId}
            />
          ))}

          <Modal id="medium-modal" appendTo="#scenario-modals" width="90%">
            <MediumProvider>
              <MediumModal
                mediumId={selectedMediumId}
                data-close
                setMediumId={setSelectedMediumId}
              />
            </MediumProvider>
          </Modal>
        </div>
      </div>
    )
  }

  if (!selectedNode) return null

  const hasTransitionEl = selectedNode.data.elements.some(
    (el) => el.kind === 'TRANSITION'
  )

  const hasTtimerEl = selectedNode.data.elements.some(
    (el) => el.kind === 'TIMER'
  )

  return (
    <>
      <section className="pt-2 pb-2 pl-3 bg-gray-background">
        <div className="flex-container align-middle align-justify">
          <h4
            className="flex-container align-middle text-bold text-normal cursor-pointer mb-0"
            onClick={handleAccordion}>
            <FaPlus />
            <span className="mr-0-5 ml-0-5">Add element</span>
            <FaChevronDown
              style={{
                transition: 'all .2s',
                transform: accOpen ? '' : 'rotate(180deg)',
              }}
            />
          </h4>
          {accOpen && (
            <a
              className="text-stable-dark text-underline text-normal pr-3"
              target="_blank"
              href="https://help.warpvr.com/scenes/jjFsB7uc6Zz1fHqDWfkVMs/scene-types/vF5kusUUzmfTN1TFe6CejE">
              Need help choosing?
            </a>
          )}
        </div>
        <div
          className="flex-container"
          style={{
            flexWrap: 'wrap',
            transition: 'all .2s',
            overflow: 'hidden',
            maxHeight: accOpen ? DEFAULT_MAX_HEIGHT : '0px',
          }}>
          <div className="pt-2">
            {ELEMENT_TYPES.filter((b) => b.isUserAddable).map((b) => {
              if (
                b.type === 'MEDIAPANEL' &&
                !currentClient.features.includes('RICHMEDIAPANEL')
              )
                return null
              return (
                <ElementButton
                  key={b.type}
                  type={b.type}
                  disabled={
                    (b.type === 'TRANSITION' && hasTransitionEl) ||
                    (b.type === 'TIMER' && hasTtimerEl)
                  }
                  loading={loading}
                  setLoading={setLoading}
                />
              )
            })}
          </div>
        </div>
      </section>

      {renderQuestions()}
    </>
  )
}
export default ElementsTab
