const { gql } = require('@apollo/client')

const SCENARIO_FIELDS = gql`
  fragment ScenarioFields on Scenario {
    id
    name
    description
    imageUrl
    statuses
    legacyFlow
    attemptsCount
    scenesCount
    updatedAt
    createdAt
    hideNadir
    voiceEnabled
    locale
    publishedScenario {
      id
    }
  }
`
const SCENARIO_DETAIL_FIELDS = gql`
  fragment ScenarioDetailFields on Scenario {
    id
    name
    description
    imageUrl
    statuses
    legacyFlow
    scenesCount
    updatedAt
    createdAt
    hideNadir
    voiceEnabled
    locale
    filmingGrouping
    scoringSystem {
      kind
      pointsPerStar
      starsForCompletion
      noStarDescription
      oneStarDescription
      twoStarDescription
      threeStarDescription
      fourStarDescription
      fiveStarDescription
      endingSettings {
        stars
        points
        description
      }
    }
    publishedScenario {
      id
    }
    pdf {
      name
      url
    }
    cameraLocations {
      id
      name
      description
    }
    testScenario {
      id
      version
      message
      status
      createdAt
      testers {
        id
        name
        email
      }
    }
    archivedScenarios {
      id
      version
      status
      createdAt
    }
  }
`

const PURCHASE_FIELDS = gql`
  fragment PurchaseFields on Purchase {
    id
    state
    buyingClient {
      id
      name
    }
    sellingClient {
      id
      name
    }
    buyingClientInformation
    productKind
    productName
    productDescription
    productLicenseType
    purchasedLicenseCount
    productLicenseDuration
    usedLicenseCount
    totalPrice
    invoicedBy
    subscriptionState
    createdAt
  }
`

const PRODUCT_FIELDS = gql`
  fragment ProductFields on Product {
    client {
      id
      name
    }
    courses {
      course {
        name
        id
      }
    }
    scenarios {
      scenario {
        name
        id
      }
    }
    createdAt
    commission
    id
    description
    kind
    licenseDuration
    licenseType
    imageUrl
    name
    price
    state
    updatedAt
  }
`

// Old video editor
const SCENE_FIELDS = gql`
  fragment SceneFields on Scene {
    id
    canvasX
    canvasY
    name
    kind
    description
    number
    northOffsetEnabled
    northOffset
    showQuestionInLookDirection
    questionFadeIn
    hasVideo
    videoLoopEnabled
    videoLoop
    x
    y
    anchorX
    anchorY
    elementSize
    answerElementSize
    question
    hideHotspotLabelsVr
    hint
    giveHintAfter
    filming
    video {
      id
      width
      height
      duration
      name
      fps
      size
      state
      stereoscopic
      previewUrl
      play4kUrl
    }
    answers {
      id
      name
      number
      points
      anchorX
      anchorY
      ending
      linkToId
      hideInVr
      hotspotSize
      elementSize
      randomizedLinkToIds
    }
  }
`

const SCENE_WITH_ELEMENTS_FIELDS = gql`
  fragment SceneWithElementsFields on Scene {
    id
    canvasX
    canvasY
    name
    description
    start
    number
    northOffsetEnabled
    northOffset
    hasVideo
    videoLoopEnabled
    videoLoop
    elementsFadeIn
    hideHotspots
    hotspotHintEnabled
    hotspotHintAfter
    elementSize
    filming
    useSameVideoAsId
    video {
      id
      width
      height
      duration
      name
      fps
      size
      state
      stereoscopic
      play4kUrl
      trimEnabled
      trimStart
      trimEnd
    }
    elements {
      id
      number
      kind
      anchorX
      anchorY
      defaultAnchorX
      defaultAnchorY
      groupKind
      groupUuid
      points
      linkToId
      linkToEnding
      randomizedLinkToIds
      description
      label
      labelSize
      showLabel
      showInLookDirection
      hotspotSize
      timerMinutes
      timerSeconds
      flatAttachment {
        id
        name
        previewUrl
        url
      }
    }
    cameraLocation {
      id
      name
      description
    }
  }
`

const MEDIA_FIELDS = gql`
  fragment MediaFields on Medium {
    name
    state
    kind
    stereoscopic
    duration
    fps
    height
    width
    size
    createdAt
    previewUrl
    playUrl
    originalUrl
    id
    percentage
    trimEnabled
    trimStart
    trimEnd
    flatAttachment {
      id
      previewUrl
      size
      duration
    }
    videoAttachment {
      id
      previewUrl
      size
      duration
    }
  }
`
export {
  PURCHASE_FIELDS,
  PRODUCT_FIELDS,
  SCENE_FIELDS,
  SCENARIO_FIELDS,
  MEDIA_FIELDS,
  SCENE_WITH_ELEMENTS_FIELDS,
  SCENARIO_DETAIL_FIELDS,
}
