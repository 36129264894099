import React, { useState } from 'react'
import logo from '../../../../images/logo.svg'
import {
  checkSsoConfigurationQuery,
  resetPasswordMutation,
  signInMutation,
  signInPasskeyMutation,
  passkeyOptions,
} from '../../apollo/query/user'
import { handleApolloError } from '../../utils/errors'
import { useLazyQuery, useMutation } from '@apollo/client'
import withApollo from '../../hooks/withApollo'
import { FaExclamationTriangle, FaKey } from 'react-icons/fa'
import { getCsrfToken } from '../../utils/helpers/graphql'
import * as WebAuthnJSON from '@github/webauthn-json'
import googleIcon from '../../../../images/icon-google.svg'

const ERRORS = {
  INVALID_USER_OR_PASSWORD:
    'You filled in in a wrong email address or password. Please try again.',
  OTP_INVALID:
    'Two-factor authentication has failed. Please try again or use a different method to sign in.',
  LOCKED:
    'Authentication has failed 10 times, therefore your account has been locked for 24 hours. Please try again later.',
  UNKNOWN_ERROR: 'Unknown error. Please try again.',
  PASSKEY_ERROR:
    'Authentication with a passkey has failed. Please try again or use a different method to sign in.',
}

const STEP_FIRST = 'FIRST'
const STEP_GET_PASSWORD = 'GET_PASSWORD'
const STEP_FORGOT_PASSWORD = 'FORGOT_PASSWORD'
const STEP_GET_CODE = 'GET_CODE'
const STEP_SSO = 'SSO'

const FORM_STEP_HEADERS = {
  [STEP_FIRST]: 'Welcome. Sign into Warp Studio',
  [STEP_GET_PASSWORD]: 'Fill in your password',
  [STEP_GET_CODE]: 'Fill in your authentication code',
  [STEP_SSO]: 'Sign in through SSO',
  [STEP_FORGOT_PASSWORD]: 'Please check your email',
}
const GOOGLE_INITIATION_PATH = '/auth/cognito?identity_provider=Google'

const passkeySupported = WebAuthnJSON.supported()
const googleSupported = false // enable once Google OAuth consent screen is updated

const getEmail = () => document.forms['signin-form'].elements['email'].value

const openChat = () => window.$crisp.push(['do', 'chat:open'])
const HavingProblems = ({ step, onForgotClick, setStep }) => {
  if (step === STEP_SSO) return <></>
  if (step === STEP_FORGOT_PASSWORD) {
    return (
      <div className="text-center text-normal">
        <p className="text-stable-dark">
          We have sent an email to {getEmail()} with link to change your
          password. Did not get the email? Check your spam folder or{' '}
          <a
            className="text-stable-dark text-underline"
            onClick={() => setStep(STEP_FIRST)}>
            go back
          </a>{' '}
          and try again.
        </p>
      </div>
    )
  }
  return (
    <>
      <h4 className="text-bold mt-4 tracking-tighter">Having problems?</h4>
      <div className="border-light border-radius flex-container flex-dir-column tracking-tighter">
        {step === STEP_FIRST && (
          <a
            href="https://help.warpvr.com/sign-in-to-the-warp-vr-app/o2VctLaXY3cbGjA86Vdpja/sign-in-with-a-6-digit-code/cw73FqPUXbdgfMJfuQnRDK"
            target="_blank"
            className="pt-1-5 pb-1-5 pl-2 text-normal text-dark border-light-bottom">
            Do you need a 6-digit code?
          </a>
        )}

        {step === STEP_GET_PASSWORD && (
          <a
            className="pt-1-5 pb-1-5 pl-2 text-normal text-dark border-light-bottom"
            onClick={onForgotClick}>
            Forgot password?
          </a>
        )}

        {step === STEP_GET_CODE && (
          <a
            href="https://help.warpvr.com"
            target="_blank"
            className="pt-1-5 pb-1-5 pl-2 text-normal text-dark border-light-bottom">
            Where can I find an authentication code?
          </a>
        )}

        <a
          onClick={openChat}
          className="pt-1-5 pb-1-5 pl-2 text-normal text-dark">
          Get in touch with our support team
        </a>
      </div>
    </>
  )
}

const renderError = (error) => {
  if (!error) return <></>
  return (
    <div className="mb-2 border-assertive border-radius p-2 text-normal text-dark flex-container align-top">
      <FaExclamationTriangle
        className="text-assertive"
        style={{ marginTop: '3px' }}
      />
      <span className="ml-1">{ERRORS?.[error] || ERRORS.UNKNOWN_ERROR}</span>
    </div>
  )
}

const SignIn = () => {
  const [step, setStep] = useState(STEP_FIRST)
  const [error, setError] = useState()

  const [signIn, { loading: signInLoading }] = useMutation(signInMutation, {
    onError: () => {},
  })
  const [resetPassword] = useMutation(resetPasswordMutation, {
    onError: handleApolloError,
  })
  const [getSsoConfig, { data: ssoConfigData, loading: ssoConfigLoading }] =
    useLazyQuery(checkSsoConfigurationQuery)

  const [getPasskeyOptions] = useLazyQuery(passkeyOptions)
  const [signInPasskey] = useMutation(signInPasskeyMutation)

  const startPasskeySignin = () => {
    setError(false)
    getPasskeyOptions({
      onCompleted: (data) => {
        const credentialOptions = JSON.parse(data.passkeyOptions)
        WebAuthnJSON.get({ publicKey: credentialOptions })
          .then((credential) => {
            signInPasskey({
              variables: {
                publicKeyCredential: JSON.stringify(credential),
              },
              onCompleted: (data) => {
                if (data?.signInPasskey) window.location.href = '/home'
              },
              onError: () => {
                setError('PASSKEY_ERROR')
              },
            })
          })
          .catch(() => {
            setError('PASSKEY_ERROR')
          })
      },
      onError: () => {
        setError('PASSKEY_ERROR')
      },
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError(false)
    const form = e.target
    const email = form.elements['email'].value

    if (step === STEP_FIRST) {
      const { data } = await getSsoConfig({
        variables: { email: getEmail() },
      })
      if (data.ssoConfiguration) return setStep(STEP_SSO)

      return setStep(STEP_GET_PASSWORD)
    }

    const rememberMe = form.elements['rememberMe'].checked
    const password = form.elements['password'].value
    const otpEl = form.elements['otp']
    const otp = otpEl?.value

    signIn({
      variables: {
        email,
        password,
        rememberMe,
        ...(step === STEP_GET_CODE ? { otp: otp } : {}),
      },
    }).then((resp) => {
      const { data } = resp
      if (data?.signIn) window.location.href = '/home'
      else {
        const err = resp.errors.message
        if (err === 'OTP_REQUIRED') setStep(STEP_GET_CODE)
        else {
          setError(err)
          if (err === 'OTP_INVALID') {
            otpEl.value = ''
            otpEl.focus()
          }
        }
      }
    })
  }

  const handleForgot = () => {
    setError(false)
    setStep(STEP_FORGOT_PASSWORD)
    resetPassword({
      variables: {
        email: getEmail(),
      },
    })
  }

  const FORM_STEP_BUTTONS = {
    [STEP_FIRST]: (
      <button type="submit" className="o-button text-bold w-100">
        Continue
      </button>
    ),
    [STEP_GET_PASSWORD]: (
      <button
        type="submit"
        className="o-button text-bold w-100"
        disabled={signInLoading}>
        Sign in
      </button>
    ),
    [STEP_GET_CODE]: (
      <button
        type="submit"
        className="o-button text-bold w-100"
        disabled={signInLoading}>
        Verify
      </button>
    ),
    [STEP_SSO]: (
      <form
        className="p-0 m-0"
        method="post"
        action={ssoConfigData?.ssoConfiguration?.initiationPath}>
        <input
          type="hidden"
          name="authenticity_token"
          value={getCsrfToken()}
          autoComplete="off"
        />
        <button className="o-button text-bold w-100">
          Sign in with {ssoConfigData?.ssoConfiguration?.name}
        </button>
      </form>
    ),
  }

  return (
    <>
      <form
        name="signin-form"
        className="c-form c-form__devise pt-4 pb-4"
        onSubmit={handleSubmit}>
        <header>
          <div className="flex-container align-middle align-center w-100 mb-5">
            <img src={logo} loading="lazy" width={65} />
            <h2 className="ml-1 text-bold mb-0 c-launcher__logo--text">
              Warp Studio
            </h2>
          </div>
          <h1 className="text-bold text-center c-form__title mt-5 mb-5 pb-1 pt-5">
            {FORM_STEP_HEADERS[step]}
          </h1>
        </header>
        {renderError(error)}
        <div className="c-form__row">
          <label htmlFor="email">
            Email
            {step !== STEP_FIRST && (
              <a
                className="u-pull-right text-dark text-normal text-underline"
                onClick={() => setStep(STEP_FIRST)}>
                Go back
              </a>
            )}
          </label>
          <input
            autoFocus
            tabIndex="1"
            type="email"
            name="email"
            id="email"
            required
            disabled={step !== STEP_FIRST || ssoConfigLoading}
            className={step === STEP_FIRST ? '' : 'bg-light border-light'}
          />
        </div>

        <div hidden={step !== STEP_GET_PASSWORD}>
          <div className="c-form__row">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              id="password"
              required={step === STEP_GET_PASSWORD}
              minLength={6}
            />
          </div>
          <div className="c-form__row">
            <label className="o-checkbox mb-2">
              <input
                type="checkbox"
                name="rememberMe"
                autoComplete="off"
                defaultChecked={false}
              />
              <span></span>Keep me signed in
            </label>
          </div>
        </div>

        {step === STEP_GET_CODE && (
          <>
            <div className="c-form__row">
              <label htmlFor="otp">Authentication code</label>
              <input
                type="text"
                name="otp"
                id="otp"
                required
                placeholder="XXXXXX"
                minLength={6}
                maxLength={6}
                autoComplete="one-time-code"
              />
            </div>

            <div className="c-form__row text-stable-dark mb-2 text-normal">
              Open your two-factor authenticator app or browser extension to
              view your authentication code.
            </div>
          </>
        )}

        {FORM_STEP_BUTTONS?.[step]}
      </form>

      {step === STEP_FIRST && (
        <>
          {(googleSupported || passkeySupported) && (
            <div className="text-center mb-1">Or</div>
          )}

          {googleSupported && (
            <form
              className="mb-1"
              method="post"
              action={GOOGLE_INITIATION_PATH}>
              <input
                type="hidden"
                name="authenticity_token"
                value={getCsrfToken()}
                autoComplete="off"
              />
              <button className="o-button text-bold w-100 bg-dark-light text-white border-dark text-normal">
                <div className="flex-container align-middle align-center">
                  <img
                    alt="Google"
                    src={googleIcon}
                    width={16}
                    height={16}
                    className="mr-1"
                  />
                  <span>Sign in with Google</span>
                </div>
              </button>
            </form>
          )}

          {passkeySupported && (
            <button
              type="button"
              className="o-button o-button--secondary text-bold text-dark w-100 text-normal"
              onClick={startPasskeySignin}>
              <div className="flex-container align-middle align-center">
                <span className="bg-royal border-radius p-0-5 mr-1 flex-container">
                  <FaKey className="text-white text-small" />
                </span>
                <span>Sign in with Passkey</span>
              </div>
            </button>
          )}
        </>
      )}

      <div className="c-form__devise">
        <HavingProblems
          step={step}
          setStep={setStep}
          onForgotClick={handleForgot}
        />
        {step === STEP_FIRST && (
          <div className="border border-radius text-normal pb-1-5 pt-1-5 mt-4 text-center">
            <span className="text-bold tracking-tighter">New to Warp VR?</span>{' '}
            <a
              className="text-assertive tracking-tighter"
              href="mailto:sales@warpvr.com">
              Get in touch with our sales team
            </a>
          </div>
        )}
      </div>
    </>
  )
}
export default withApollo(SignIn)
