import React, { useState, useEffect } from 'react'
import { FaPlus } from 'react-icons/fa'
import { useLazyQuery } from '@apollo/client'
import { FiX } from 'react-icons/fi'
import SearchableDropdown from '../UI/Menu/SearchableDropdown'
import { getCoursesQuery } from '../../apollo/query/courses'
import { getPublishedScenariosQuery } from '../../apollo/query/scenarios'

const GroupsList = ({
  type,
  selectionRef,
  currentList,
  purchasedItems = [],
}) => {
  const [show, setShow] = useState(false)
  const [list, setList] = useState([])
  const [allList, setAllList] = useState([])

  const parsedItem = (item) => ({
    render:
      item.purchased ||
      purchasedItems.find((p) => {
        return p.id === item.id && p.name === item.name
      }) ? (
        <>
          <span className='flex-container align-middle'>
            {item.name}
            <label className="o-label--custom mb-0 o-label--stable-lighter text-small text-bold text-stable-dark ml-1">
              PURCHASED
            </label>
          </span>
        </>
      ) : (
        item.name
      ),
    value: item.name,
    id: item.id,
    purchased: item.purchased,
  })

  useEffect(() => {
    if (currentList) setList(currentList.map((item) => parsedItem(item)))
  }, [currentList])

  useEffect(() => {
    selectionRef.current = list.map(({ id }) => id)
  }, [list])

  const [getCourses, { loading: loadingCourses }] = useLazyQuery(
    getCoursesQuery,
    {
      fetchPolicy: 'network-only',
      onCompleted: ({ courses }) => {
        const ids = list.map(({ id }) => id)
        setAllList(
          [
            ...courses,
            ...purchasedItems.filter(
              (pi) => !courses.find((al) => al.id === pi.id)
            ),
          ].reduce((a, course) => {
            if (!ids.includes(course.id)) {
              a.push(parsedItem(course))
            }
            return a
          }, [])
        )
      },
    }
  )

  const [getScenarios, { loading: loadingScenarios }] = useLazyQuery(
    getPublishedScenariosQuery,
    {
      fetchPolicy: 'network-only',
      onCompleted: ({ publishedScenarios }) => {
        const ids = list.map(({ id }) => id)
        setAllList(
          [
            ...publishedScenarios,
            ...purchasedItems.filter(
              (pi) => !publishedScenarios.find((ps) => ps.id === pi.id)
            ),
          ].reduce((a, s) => {
            if (s && !ids.includes(s.id)) {
              a.push(parsedItem(s))
            }
            return a
          }, [])
        )
      },
    }
  )

  const add = (item) => {
    setList([...list, item])
  }
  const remove = (itemId) => {
    setList(list.filter(({ id }) => id !== itemId))
  }

  return (
    <>
      {list.length ? (
        <ul className="o-modal__video--list border-stable border-radius">
          {list.map((item) => (
            <li key={item.id} style={{ padding: '0 20px' }}>
              <div
                style={{
                  flexGrow: '1',
                  display: 'inline-flex',
                  alignItems: 'center',
                }}>
                <span style={{ flexGrow: '1' }}>{item.render}</span>
                <span
                  className="cursor-pointer"
                  style={{ display: 'flex', alignItems: 'center' }}
                  onClick={() => remove(item.id)}>
                  <FiX style={{ marginLeft: '10px' }} size="16" />
                </span>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <div
          style={{
            display: 'grid',
            placeItems: 'center',
            height: '100px',
            border: '1px solid #dddddd',
            borderRadius: '5px',
          }}>
          <p className="text-italic mb-0">No {type}s selected yet...</p>
        </div>
      )}
      <button
        className="mt-1 mb-2 button hollow secondary text-bold"
        onClick={(e) => {
          e.preventDefault()
          switch (type) {
            case 'course':
              getCourses()
              break
            case 'scenario':
              getScenarios()
              break
            default:
              throw new Error('Wrong type')
          }
          setShow(true)
        }}>
        <FaPlus className="mr-1" /> Select {type}
      </button>
      {show && (
        <SearchableDropdown
          placeholder={`Search by ${type} name`}
          clickHandler={(item) => {
            add(item)
            setShow(false)
          }}
          hideHandler={() => setShow(false)}
          loading={loadingCourses || loadingScenarios}
          notFoundTitle={`No ${type}s found`}
          notFoundText={
            <small>{`You don't have any ${type}s to add or you have linked this group to all ${type}s`}</small>
          }
          data={allList.filter(
            (item) => !selectionRef.current.includes(item.id)
          )}
        />
      )}
    </>
  )
}

export default GroupsList
