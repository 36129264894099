import React, { useContext, useMemo, useState } from 'react'
import { convertDuration } from '../../utils/format'
import {
  getAttemptMetricsDayQuery,
  getAttemptMetricsHourQuery,
  getAttemptMetricsMinutesQuery,
} from '../../apollo/query/attempts'
import { useQuery } from '@apollo/client'
import Delay from '../../utils/helpers/Delay'
import LoadingSpinner from '../../components/UI/LoadingSpinner'
import AttemptsTab from '../../components/Activity/AttemptsTab'
import TraineesTab from '../../components/Activity/TraineesTab'
import ScenariosTab from '../../components/Activity/ScenariosTab'
import PlayingTab from '../../components/Activity/PlayingTab'
import HeaderFilters from '../../components/Activity/HeaderFilters'
import ActivityContext from '../../utils/context/ActivityContext'
import MostPlayedScenarios from '../../components/Activity/MostPlayedScenarios'
import MostActiveTrainees from '../../components/Activity/MostActiveTrainees'
import MostActiveGroups from '../../components/Activity/MostActiveGroups'
import CompletionRate from '../../components/Activity/CompletionRate'
import DeviceType from '../../components/Activity/DeviceType'
import ActivityStars from '../../components/Activity/Stars'
import TableColumnsDropdown from '../../components/UI/TableColumnsDropdown'
import Checkbox from '../../components/UI/Form/Checkbox'
import { filterColumnsHandler } from '../../components/tableFunctions'
import { FaThLarge } from 'react-icons/fa'
import usePreferences from '../../hooks/usePreferences'

const TABLE_HEADERS = [
  'Training attempts',
  'Most played scenarios',
  'Most active trainees',
  'Most active groups',
  'Completion rate',
  'Device type',
  'Stars',
]

const Tab = ({ title, selected, onClick, value, percent }) => {
  /*const getPercentClass = () => {
    if (percent > 0) return 'text-success'
    if (percent < 0) return 'text-assertive'
    return 'text-stable-dark'
  }
  const getPercentSign = () => {
    if (percent > 0) return '+'
    if (percent < 0) return '-'
    return ''
  }*/

  const handleClick = (e) => {
    e.preventDefault()
    onClick()
  }

  return (
    <a
      href="#"
      role="button"
      className={`cell small-12 medium-3 p-2 border cursor-pointer border-radius mb-1 ${
        selected ? 'bg-dark-light' : 'hover-bg-stable-lightest'
      }`}
      onClick={handleClick}>
      <header
        className={`text-left mb-1 text-normal ${
          selected ? 'text-white' : 'text-stable-dark'
        }`}>
        {selected && <span className="o-bullet o-bullet--assertive mr-1" />}
        {title}
      </header>
      <footer className="flex-container align-justify align-middle">
        <h2
          className={`text-bold mb-0 ${selected ? 'text-white' : 'text-dark'}`}>
          {value}
        </h2>
        {/* <span className={`text-normal text-bold ${getPercentClass()}`}>
          {getPercentSign()}&nbsp;{Math.abs(percent)}%
        </span> */}
      </footer>
    </a>
  )
}

const Overview = () => {
  const { apiVars, searchParams } = useContext(ActivityContext)
  const [showEmptyState, setShowEmptyState] = useState(false)
  const [selectedTab, setSelectedTab] = useState('Training attempts')
  const [preferences, setPreferences] = usePreferences('activities')

  const query = useMemo(() => {
    if (searchParams.period === '1-hour') return getAttemptMetricsMinutesQuery
    if (searchParams.period === '24-hours') return getAttemptMetricsHourQuery
    return getAttemptMetricsDayQuery
  }, [searchParams.period])

  const { data: attemptsMetricsData } = useQuery(query, {
    variables: { ...apiVars, metric: 'COUNT' },
    fetchPolicy: 'no-cache',
  })

  const { data: scenariosData } = useQuery(query, {
    variables: { ...apiVars, metric: 'SCENARIOS' },
    fetchPolicy: 'no-cache',
    skip: !Number(attemptsMetricsData?.attemptsMetrics?.total),
  })

  const { data: traineesData } = useQuery(query, {
    variables: { ...apiVars, metric: 'TRAINEES' },
    fetchPolicy: 'no-cache',
    skip: !Number(attemptsMetricsData?.attemptsMetrics?.total),
  })

  const { data: playingData } = useQuery(query, {
    variables: { ...apiVars, metric: 'DURATION' },
    fetchPolicy: 'no-cache',
    skip: !Number(attemptsMetricsData?.attemptsMetrics?.total),
  })

  // const { data: devicesData } = useQuery(getAttemptMetricsQuery, {
  //   variables: { ...apiVars, metric: 'COUNT' },
  //   skip: !apiVars
  // })

  if (!attemptsMetricsData || !preferences)
    return (
      <Delay>
        <LoadingSpinner />
      </Delay>
    )

  const getTabs = () => {
    const tabs = [
      {
        title: 'Training attempts',
        value: attemptsMetricsData.attemptsMetrics.total,
        // percent: 22,
        content: <AttemptsTab data={attemptsMetricsData} apiVars={apiVars} />,
      },
      {
        title: 'Scenario played',
        value: scenariosData?.attemptsMetrics?.total ?? 0,
        // percent: 0,
        content: <ScenariosTab data={scenariosData} />,
      },
      {
        title: 'Trainees playing',
        value: traineesData?.attemptsMetrics?.total ?? 0,
        // percent: 22,
        content: <TraineesTab data={traineesData} />,
      },
      // {
      //   title: 'Unique devices',
      //   value: 213,
      //   percent: 22,
      // },
      {
        title: 'Playing time',
        value: convertDuration(playingData?.attemptsMetrics?.total),
        // percent: -2,
        content: <PlayingTab data={playingData} />,
      },
    ]

    return (
      <>
        <div className="grid-container fluid">
          <div className="grid-margin-x grid-x pb-3">
            {tabs.map((tab) => (
              <Tab
                key={tab.title}
                {...tab}
                selected={selectedTab === tab.title}
                onClick={() => setSelectedTab(tab.title)}
              />
            ))}
          </div>
        </div>
        {tabs.find((t) => t.title === selectedTab).content}
      </>
    )
  }

  const getTableRightBar = () => {
    const content = () => {
      return TABLE_HEADERS.map((item, i) => {
        const disabled = i === 0
        return (
          <li
            key={i}
            className={`mb-0 text-normal o-dropdown__list-item ${
              disabled ? 'disabled' : ''
            }`}
            onClick={(e) => {
              if (!disabled) {
                filterColumnsHandler(item, preferences, setPreferences)
              }
            }}>
            <Checkbox
              style={{ marginBottom: '0px', cursor: 'default' }}
              disabled={disabled}
              checked={!preferences.filteredColumns.includes(item)}
              value={item}
            />
          </li>
        )
      })
    }

    return (
      <>
        <TableColumnsDropdown
          content={content}
          icon={<FaThLarge style={{ marginRight: '8px' }} />}
        />
      </>
    )
  }

  return (
    <div id="activity-overview">
      <HeaderFilters
        showEmptyState={showEmptyState}
        setShowEmptyState={setShowEmptyState}
        title="Activity"
        apiVars={apiVars}
        rightBar={getTableRightBar()}
      />
      {getTabs()}

      <div className="grid-container fluid row mt-4 pt-2 w-100 small-unstack">
        <div className="row w-100">
          {!preferences.filteredColumns.includes('Most played scenarios') && (
            <div className="columns medium-expand small-12 mb-3">
              <MostPlayedScenarios attemptsMetricsData={attemptsMetricsData} />
            </div>
          )}
          {!preferences.filteredColumns.includes('Most active trainees') && (
            <div className="columns medium-expand small-12 mb-3">
              <MostActiveTrainees attemptsMetricsData={attemptsMetricsData} />
            </div>
          )}
          {!preferences.filteredColumns.includes('Most active groups') && (
            <div className="columns medium-expand small-12 mb-3">
              <MostActiveGroups attemptsMetricsData={attemptsMetricsData} />
            </div>
          )}
        </div>
        <div className="row w-100">
          {!preferences.filteredColumns.includes('Completion rate') && (
            <div className="columns medium-expand small-12 mb-3 mt-3">
              <CompletionRate attemptsMetricsData={attemptsMetricsData} />
            </div>
          )}
          {!preferences.filteredColumns.includes('Device type') && (
            <div className="columns medium-expand small-12 mb-3 mt-3">
              <DeviceType attemptsMetricsData={attemptsMetricsData} />
            </div>
          )}
          {!preferences.filteredColumns.includes('Stars') && (
            <div className="columns medium-expand small-12 mb-3 mt-3">
              <ActivityStars attemptsMetricsData={attemptsMetricsData} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default Overview
